import { createContext, useContext, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { Analytics, getAnalytics, setUserProperties } from "firebase/analytics";
import {
    RemoteConfig,
    fetchAndActivate,
    getRemoteConfig,
    getValue
} from "firebase/remote-config";

import * as firebaseConfig from "./firebase-config.json";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
    pricePercentage: 1
};

const FirestoreContext = createContext<Firestore | null>(null);
const AnalyticsContext = createContext<Analytics | null>(null);
const RemoteConfigContext = createContext<RemoteConfig | null>(null);

export default function FirebaseProvider(props: { children: JSX.Element }) {
    useEffect(() => {
        const fetchConfig = async () => {
            await fetchAndActivate(remoteConfig);
            const discount = getValue(
                remoteConfig,
                "pricePercentage"
            ).asNumber();
            switch (discount) {
                case 0.84:
                    setUserProperties(analytics, {
                        discount_tier: "discount_16"
                    });
                    break;
                case 0.92:
                    setUserProperties(analytics, {
                        discount_tier: "discount_8"
                    });
                    break;
                default:
                    setUserProperties(analytics, { discount_tier: "default" });
                    break;
            }
        };
        fetchConfig();
    }, []);

    // TODO If you get an error about initialising Firebase twice, initialise
    // the Firebase app here with a useMemo.
    return (
        <FirestoreContext.Provider value={db}>
            <AnalyticsContext.Provider value={analytics}>
                <RemoteConfigContext.Provider value={remoteConfig}>
                    {props.children}
                </RemoteConfigContext.Provider>
            </AnalyticsContext.Provider>
        </FirestoreContext.Provider>
    );
}

export function useFirestore() {
    const context = useContext(FirestoreContext);
    if (!context) {
        throw new Error("useFirestore called outside of FirestoreContext");
    }
    return context;
}

export function useAnalytics() {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error("useAnalytics called outside of AnalyticsContext");
    }
    return context;
}

export function useRemoteConfig() {
    const context = useContext(RemoteConfigContext);
    if (!context) {
        throw new Error(
            "useRemoteConfig called outside of RemoteConfigContext"
        );
    }
    return context;
}
