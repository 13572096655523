import { addDoc, collection, Timestamp } from "firebase/firestore";
import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";
import { useFirestore } from "./FirebaseProvider";

interface LocalStorageObj {
    activeQuoteId: string;
}

interface LocalStorageContextValue {
    localStorageObj: LocalStorageObj;
    setLocalStorageObj: Dispatch<SetStateAction<LocalStorageObj>>;
}

const LocalStorageContext = createContext<LocalStorageContextValue | null>(
    null
);

export default function LocalStorageProvider(props: { children: JSX.Element }) {
    const db = useFirestore();
    const [localStorageObj, setLocalStorageObj] = useState({
        activeQuoteId: ""
    });

    useEffect(() => {
        const activeQuoteId = localStorage.getItem("activeQuoteId");
        if (activeQuoteId) {
            setLocalStorageObj({ activeQuoteId });
            return;
        }
        const prepareLocalStorage = async () => {
            const quoteRef = await addDoc(collection(db, "quotes"), {
                date: Timestamp.now(),
                status: "draft"
            });
            if (!quoteRef) {
                throw new Error("No quote reference for newly added quote");
            }
            localStorage.setItem("activeQuoteId", quoteRef.id);
            setLocalStorageObj({ activeQuoteId: quoteRef.id });
        };
        prepareLocalStorage();
    }, [db]);

    return (
        <LocalStorageContext.Provider
            value={{ localStorageObj, setLocalStorageObj }}
        >
            {props.children}
        </LocalStorageContext.Provider>
    );
}

export function useLocalStorage() {
    const localStorageObj = useContext(LocalStorageContext);
    if (!localStorageObj) {
        throw new Error("useLocalStorage() called outside of Context");
    }
    return localStorageObj;
}
