import { logEvent } from "firebase/analytics";
import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentReference,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    QueryDocumentSnapshot,
    setDoc,
    Timestamp
} from "firebase/firestore";
import { getValue } from "firebase/remote-config";
import { useEffect, useState } from "react";
import {
    useAnalytics,
    useFirestore,
    useRemoteConfig
} from "./FirebaseProvider";
import { CardProduct, Checkout, Product, Quote, Subscription } from "./index.d";
import { useLocalStorage } from "./LocalStorageProvider";

const QUOTES_COLL = "quotes";

// TODO It's possible that a useLocalStorage hook is needed, because changes in
// the local storage don't update the UI. Having a hook would cause this update
// in all components using it.

export function useActiveQuote() {
    const [products, setProducts] = useState<
        QueryDocumentSnapshot<CardProduct>[]
    >([]);
    const db = useFirestore();
    const { localStorageObj } = useLocalStorage();
    const quoteId = localStorageObj.activeQuoteId;
    if (!quoteId) {
        throw new Error("No current active quote in local storage");
    }

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db,
                QUOTES_COLL,
                quoteId,
                "products"
            ) as CollectionReference<CardProduct>,
            prodSnap => setProducts(prodSnap.docs)
        );
        return unsubscribe;
    }, [db, quoteId]);

    return products;
}

type ProductOperation =
    | {
          type: "add";
          product: Product;
      }
    | {
          type: "set";
          product: { objectID: string; quantity: number };
      }
    | {
          type: "delete";
          product: { objectID: string };
      };

export function useProductDispatch() {
    const db = useFirestore();
    const { localStorageObj } = useLocalStorage();
    const quoteId = localStorageObj.activeQuoteId;

    if (!quoteId) {
        throw new Error("No current active quote in local storage");
    }

    return async (op: ProductOperation) => {
        const { type, product: p } = op;
        switch (type) {
            case "add":
                return setDoc(
                    doc(db, QUOTES_COLL, quoteId, "products", p.objectID),
                    p
                );
            case "set":
                return setDoc(
                    doc(db, QUOTES_COLL, quoteId, "products", p.objectID),
                    p,
                    { merge: true }
                );
            case "delete":
                return deleteDoc(
                    doc(db, QUOTES_COLL, quoteId, "products", p.objectID)
                );
        }
    };
}

/**
 * Logs an error to Firestore
 */
export async function logError(db: Firestore, e?: Error) {
    return addDoc(collection(db, "errors"), {
        time: Timestamp.now(),
        message: e?.message,
        stack: e?.stack
    });
}

/**
 * Returns a function logging an event to Analytics
 */
export function useLogEvent() {
    const analytics = useAnalytics();

    return (
        name: string,
        params: { [k: string]: string | number | object }
    ) => {
        logEvent(analytics, name, params);
    };
}

/**
 * Returns a function logging a search query to Firestore and Analytics
 */
export function useRecordQuery() {
    const db = useFirestore();
    const logEvent = useLogEvent();

    return async (query: string) => {
        logEvent("search", { search_term: query });
        await addDoc(collection(db, "queries"), {
            date: Timestamp.now(),
            text: query
        });
    };
}

export function useQuote(quoteId: string) {
    const db = useFirestore();
    const [products, setProducts] = useState([] as CardProduct[]);
    const [date, setDate] = useState(undefined as Date | undefined);
    const [name, setName] = useState("");

    useEffect(() => {
        const fetchQuote = async () => {
            const products = await getDocs(
                collection(
                    db,
                    QUOTES_COLL,
                    quoteId,
                    "products"
                ) as CollectionReference<CardProduct>
            );
            const quoteSnap = await getDoc(
                doc(db, QUOTES_COLL, quoteId) as DocumentReference<{
                    date: Timestamp;
                    name: string;
                }>
            );
            setProducts(products.docs.map(ds => ds.data()));
            setDate(quoteSnap.data()?.date.toDate());
            setName(quoteSnap.data()?.name || "");
        };
        fetchQuote();
    }, [db, quoteId]);

    return { products, date, name } as Quote;
}

export function useAddSubscription() {
    const db = useFirestore();

    return async (s: Subscription) => {
        const subData = {
            date: Timestamp.fromDate(s.date),
            reason: s.reason,
            additionalInfo: s.additionalInfo
        };
        const subRef = doc(db, "subscribers", s.phone);
        const subSnap = await getDoc(subRef);
        if (!subSnap.exists()) {
            await setDoc(subRef, { name: s.name, phone: s.phone });
        }
        await addDoc(
            collection(db, "subscribers", s.phone, "reasons"),
            subData
        );
    };
}

type QuoteOperation =
    | {
          type: "add";
      }
    | {
          type: "delete";
          value: { quoteId: string };
      }
    | {
          type: "to_active";
          value: { date: Date; quoteId: string };
      }
    | {
          type: "to_final";
          value: { date: Date; name: string };
      };

export function useQuoteDispatch() {
    const db = useFirestore();
    const { localStorageObj, setLocalStorageObj } = useLocalStorage();

    const quoteDispatch = async (op: QuoteOperation) => {
        const { activeQuoteId } = localStorageObj;
        switch (op.type) {
            case "add":
                return addDoc(collection(db, QUOTES_COLL), {
                    date: Timestamp.now(),
                    status: "draft"
                });
            case "delete":
                return deleteDoc(doc(db, QUOTES_COLL, op.value.quoteId));
            case "to_final":
                if (!activeQuoteId) {
                    throw new Error("No active quote ID in local storage");
                }
                await setDoc(
                    doc(db, QUOTES_COLL, activeQuoteId),
                    {
                        date: op.value.date,
                        name: op.value.name,
                        status: "final"
                    },
                    { merge: true }
                );
                const newQuoteRef = (await quoteDispatch({
                    type: "add"
                })) as DocumentReference;
                if (newQuoteRef) {
                    setLocalStorageObj({ activeQuoteId: newQuoteRef.id });
                    localStorage.setItem("activeQuoteId", newQuoteRef.id);
                } else {
                    throw new Error("Added new quote, but it has no ID");
                }
                return newQuoteRef;
            case "to_active":
                await quoteDispatch({
                    type: "delete",
                    value: { quoteId: activeQuoteId }
                });
                await setDoc(
                    doc(db, QUOTES_COLL, op.value.quoteId),
                    {
                        date: op.value.date,
                        status: "draft"
                    },
                    { merge: true }
                );
                setLocalStorageObj({ activeQuoteId: op.value.quoteId });
                localStorage.setItem("activeQuoteId", op.value.quoteId);
                return op.value.quoteId;
        }
    };
    return quoteDispatch;
}

type CheckoutOperation = {
    type: "add";
    value: Checkout;
};

export function useCheckoutDispatch() {
    const db = useFirestore();

    return async (op: CheckoutOperation) => {
        switch (op.type) {
            case "add":
                return addDoc(
                    collection(
                        db,
                        "checkouts"
                    ) as CollectionReference<Checkout>,
                    {
                        ...op.value,
                        date: Timestamp.now()
                    }
                );
        }
    };
}

export function usePricePercentage() {
    const remoteConfig = useRemoteConfig();

    return getValue(remoteConfig, "pricePercentage").asNumber();
}
