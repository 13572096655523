import { FC, FormEvent, useRef, useState } from "react";
import { /*useLoaderData,*/ LoaderFunctionArgs } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { CardProduct } from "./index.d";
import NoSearchHits from "./NoSearchHits";
import {
    fetchLiveData,
    assignQuantity,
    updateWithLiveData,
    useRunQuery
} from "./search-controller";
import { useDisplayError } from "./AlertsProvider";
import ProductCard from "./ProductCard";
import { useActiveQuote } from "./model";

export default function Search() {
    return <SearchController view={SearchView} />;
}

export function loader(args: LoaderFunctionArgs) {
    return {
        searchParams: new URL(args.request.url).searchParams
    };
}

function SearchController(props: {
    view: FC<{
        products: CardProduct[];
        submitSearch: (q: string) => Promise<void>;
    }>;
}) {
    // TODO change page title with useEffect

    // const loaderData = useLoaderData() as { searchParams: URLSearchParams };

    const [products, setProducts] = useState<CardProduct[]>([]);
    const quoteProds = useActiveQuote();
    const runQuery = useRunQuery();

    const submitSearch = async (query: string) => {
        const queryProds = await runQuery(query);
        setProducts(assignQuantity(queryProds, quoteProds));
        const liveData = await fetchLiveData(queryProds);
        setProducts(products => updateWithLiveData(products, liveData));
    };

    return props.view({ products, submitSearch });
}

function SearchView(props: {
    products: CardProduct[];
    submitSearch: (q: string) => Promise<void>;
}) {
    const [formValidated, setFormValidated] = useState(false);
    const [query, setQuery] = useState("");
    const [firstPass, setFirstPass] = useState(false);
    const displayError = useDisplayError();
    const lastQuery = useRef<string>("");

    const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            setFormValidated(true);
            return;
        }
        setFormValidated(false);
        try {
            await props.submitSearch(query);
        } catch (e) {
            const err = e as Error;
            displayError(err);
        }
        lastQuery.current = query;
        setFirstPass(true);
    };

    return (
        <>
            <Row className="mb-2">
                <Col className="text-center">
                    <p className="display-6">
                        The Plumbing Marketplace for Tradespeople
                    </p>
                    <p className="fs-6">
                        Cross-Merchant Quotes and Materials out of One Hand 🛠️
                    </p>
                </Col>
            </Row>
            <Form
                noValidate
                onSubmit={handleSearch}
                validated={formValidated}
                className="mb-3"
            >
                <Form.Group
                    as={Row}
                    controlId="form-search"
                    className="mb-4 mb-md-3"
                >
                    <Col sm="9" md="10" className="mb-3 mb-sm-0">
                        <Form.Control
                            placeholder="Vaillant ecoTEC Pro 28 ..."
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            required
                            className="shadow-sm"
                        />
                        <Form.Control.Feedback type="invalid">
                            Search input required
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="3" md="2">
                        <Button
                            type="submit"
                            className={
                                "w-100 button-qmp py-2"
                            }
                        >
                            Search
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
            {firstPass && props.products.length === 0 && (
                <NoSearchHits query={lastQuery.current} />
            )}
            <Row xs="1" md="2" xl="3" className="gy-2">
                {props.products.map((p, i) => (
                    <Col key={i}>
                        <ProductCard product={p} index={i} highlightResults />
                    </Col>
                ))}
            </Row>
        </>
    );
}
