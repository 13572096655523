import Alert from "react-bootstrap/Alert";
import { createContext, useContext, useState } from "react";
import { useFirestore } from "./FirebaseProvider";
import { logError } from "./model";

const SuccessContext = createContext<{
    setShowSuccess: (_: boolean) => void;
    setSuccessMsg: (_: string) => void;
} | null>(null);

const ErrorContext = createContext<{
    setShowError: (_: boolean) => void;
    setErrorMsg: (_: string) => void;
} | null>(null);

export function AlertsProvider(props: { children: JSX.Element }) {
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const alertClasses = "mb-4 mx-3 fixed-bottom shadow";
    return (
        <SuccessContext.Provider value={{ setShowSuccess, setSuccessMsg }}>
            <ErrorContext.Provider value={{ setShowError, setErrorMsg }}>
                {props.children}
                <Alert
                    variant="success"
                    show={showSuccess}
                    className={alertClasses}
                >
                    {successMsg}
                </Alert>
                <Alert
                    variant="danger"
                    show={showError}
                    className={alertClasses}
                >
                    {errorMsg}
                </Alert>
            </ErrorContext.Provider>
        </SuccessContext.Provider>
    );
}

export function useDisplaySuccess() {
    const context = useContext(SuccessContext);
    if (!context) {
        throw new Error("useDisplaySuccess() called outside of SuccessContext");
    }
    const { setShowSuccess, setSuccessMsg } = context;

    return (message?: string) => {
        message = message || "Success!";
        setSuccessMsg(message);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 6000);
    };
}

export function useDisplayError() {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error("useDisplayError() called outside of ErrorContext");
    }
    const db = useFirestore();
    const { setShowError, setErrorMsg } = context;

    return async (msgOrErr?: string | Error) => {
        let displayMsg = "";
        if (msgOrErr instanceof Error) {
            displayMsg =
                msgOrErr.message + " Sorry! Please refresh and try again.";
            await logError(db, msgOrErr);
        } else {
            displayMsg = msgOrErr || "Error!";
        }

        setErrorMsg(displayMsg);
        setShowError(true);
        setTimeout(() => setShowError(false), 6000);
    };
}
