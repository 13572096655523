import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProductCard from "./ProductCard";

import Button from "react-bootstrap/Button";
import { CardProduct } from "./index.d";
import Offcanvas from "react-bootstrap/Offcanvas";
import QuoteSummaryView from "./QuoteSummaryView";

function EmptyActiveQuoteView() {
    const background = "#f3f3f3";
    return (
        <Card
            className="border-0 text-center mx-auto mt-4"
            style={{ background, maxWidth: "400px" }}
        >
            <Card.Body>
                <p>Empty quote 🪣</p>
                <p>Try adding some products from the search</p>
            </Card.Body>
        </Card>
    );
}

interface QuoteSidebarViewProps {
    products: CardProduct[];
    show: boolean;
    onClose: () => void;
    onViewQuote: () => void;
}

export default function QuoteSidebarView(props: QuoteSidebarViewProps) {
    return (
        <Offcanvas show={props.show} onHide={props.onClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Your Quote</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Row xs="1" className="gy-3">
                    {(props.products.length === 0 && (
                        <Col>
                            <EmptyActiveQuoteView />
                        </Col>
                    )) || (
                        <>
                            <QuoteSummaryView products={props.products} />
                            <Col className="text-end">
                                <Button
                                    className="button-qmp"
                                    onClick={props.onViewQuote}
                                >
                                    Go To Quote
                                </Button>
                            </Col>
                            <Col>
                                <Row xs="1" className="gy-2">
                                    {props.products.map((p, i) => (
                                        <Col key={i}>
                                            <ProductCard
                                                product={p}
                                                index={i}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
