import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import FirebaseProvider from "./FirebaseProvider";
import App from "./App";
import ErrorPage from "./ErrorPage";
import Search, { loader as searchLoader } from "./Search";
import ActiveQuote from "./ActiveQuote";
import Quote, { loader as quoteLoader } from "./Quote";
import LocalStorageProvider from "./LocalStorageProvider";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<App />} errorElement={<ErrorPage />}>
                <Route index element={<Search />} loader={searchLoader} />
                <Route path="active-quote" element={<ActiveQuote />} />
                <Route
                    path="quote/:quoteId?"
                    element={<Quote />}
                    loader={quoteLoader}
                    errorElement={<ErrorPage />}
                />
            </Route>
        </>
    )
);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StrictMode>
        <FirebaseProvider>
            <LocalStorageProvider>
                <RouterProvider router={router} />
            </LocalStorageProvider>
        </FirebaseProvider>
    </StrictMode>
);
