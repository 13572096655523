import Col from "react-bootstrap/esm/Col";
import { CardProduct } from "./index.d";

export default function QuoteSummaryView(props: { products: CardProduct[] }) {
    const subTotal = props.products.reduce(
        (acc, p) => acc + (p.price || 0) * p.quantity,
        0
    );
    const subtotalFormatted = subTotal.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
    });
    const vatFormatted = (0.2 * subTotal).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
    });
    const totalFormatted = (1.2 * subTotal).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
    });

    return (
        <>
            <Col className="d-flex justify-content-between px-4">
                <p className="fs-6">Subtotal</p>
                <p className="fs-6 text-end">{subtotalFormatted}</p>
            </Col>
            <Col className="d-flex justify-content-between px-4 mt-0">
                <p className="fs-6">VAT 20%</p>
                <p className="fs-6 text-end">{vatFormatted}</p>
            </Col>
            <Col className="d-flex justify-content-between px-4 mt-0">
                <p className="fs-5">Total</p>
                <p className="fs-4 text-end">{totalFormatted}</p>
            </Col>
        </>
    );
}
