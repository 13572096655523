import { FormEvent, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/esm/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import PhoneInput, {
    isPossiblePhoneNumber,
    Value
} from "react-phone-number-input/mobile";
import { useDisplayError } from "./AlertsProvider";

interface WaContactFormViewProps {
    handleSubmit: (name: string, phone: string) => Promise<void>;
}

export default function WaContactFormView(props: WaContactFormViewProps) {
    const [formValidated, setFormValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState<Value | undefined>(undefined);
    const [possiblePhoneNum, setPossiblePhoneNum] = useState(false);
    const displayError = useDisplayError();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity() || !possiblePhoneNum) {
            setFormValidated(true);
            return;
        }
        setLoading(true);
        setFormValidated(false);
        try {
            await props.handleSubmit(name, phone?.slice(1) || "");
        } catch (e) {
            const err = e as Error;
            displayError(err);
        }
        setLoading(false);
    };

    const phoneChanged = (num: Value | undefined) => {
        setPossiblePhoneNum(isPossiblePhoneNumber(num || ""));
        setPhone(num);
    };

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Row} xs="1" className="gy-3 text-start">
                <Col>
                    <Form.Control
                        placeholder="Name"
                        className="shadow-sm"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        isValid={formValidated && name.length > 0}
                        isInvalid={formValidated && !name}
                    />
                    <Form.Control.Feedback type="invalid">
                        Name required
                    </Form.Control.Feedback>
                </Col>
                <Col>
                    <PhoneInput
                        defaultCountry="GB"
                        inputComponent={Form.Control}
                        value={phone}
                        onChange={phoneChanged}
                        isInvalid={
                            formValidated && (!phone || !possiblePhoneNum)
                        }
                        isValid={formValidated && phone && possiblePhoneNum}
                        placeholder="Mobile Number"
                        className="shadow-sm"
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={
                            formValidated && !possiblePhoneNum
                                ? "d-block"
                                : "none"
                        }
                    >
                        {phone
                            ? "Invalid phone number format"
                            : "Mobile number required"}
                    </Form.Control.Feedback>
                </Col>
                <Col>
                    <Button
                        className="w-100 button-qmp"
                        type="submit"
                        disabled={loading}
                    >
                        {(loading && <Spinner variant="light" size="sm" />) ||
                            "Notify me on WhatsApp!"}
                    </Button>
                </Col>
            </Form.Group>
        </Form>
    );
}
