import { QueryDocumentSnapshot } from "firebase/firestore";
import { CardProduct } from "./index.d";

export function analyticsFromProductSnaps(
    products: QueryDocumentSnapshot<CardProduct>[]
) {
    return {
        currency: "GBP",
        value: products.reduce((acc, p) => acc + (p.data().price || 0), 0),
        items: products.map((p, i) => {
            const pd = p.data();
            return {
                item_id: pd.objectID,
                item_name: pd.name,
                affiliation: pd.merchant,
                index: i,
                price: pd.price || 0
            };
        })
    };
}
