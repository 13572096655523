import { useLogEvent, useProductDispatch } from "./model";
import { CardProduct } from "./index.d";
import { FC } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { formatValues } from "./formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function ProductCard(props: {
    product: CardProduct;
    index: number;
    highlightResults?: boolean;
}) {
    return (
        <ProductCardController
            product={props.product}
            index={props.index}
            view={(viewProps: ProductCardViewProps) => (
                <ProductCardView
                    {...viewProps}
                    highlightResults={props.highlightResults}
                />
            )}
        />
    );
}

interface ProductCardViewProps {
    p: CardProduct;
    changeQty: (v: number) => void;
    handleClick: () => void;
    highlightResults?: boolean;
}

function ProductCardController(props: {
    product: CardProduct;
    index: number;
    view: FC<ProductCardViewProps>;
}) {
    const { product, index } = props;
    const productsDispatch = useProductDispatch();
    const logEvent = useLogEvent();

    const prodAnalytics = {
        currency: "GBP",
        value: product.price || 0,
        items: [
            {
                item_id: product.objectID,
                item_name: product.name,
                affiliation: product.merchant,
                index,
                price: product.price || 0,
                quantity: 1
            }
        ]
    };

    const changeQty = async (v: number) => {
        const oldQty = product.quantity;
        product.quantity += v;
        if (oldQty === 0) {
            await productsDispatch({ type: "add", product });
            logEvent("add_to_cart", prodAnalytics);
        } else {
            if (product.quantity === 0) {
                await productsDispatch({ type: "delete", product });
                const pq = { ...prodAnalytics };
                pq.items[0].quantity = oldQty;
                logEvent("remove_from_cart", prodAnalytics);
            } else {
                await productsDispatch({ type: "set", product });
            }
        }
    };

    const handleClick = () => {
        logEvent("view_item", prodAnalytics);
    };

    return props.view({ p: product, changeQty, handleClick });
}

function ProductCardView(props: ProductCardViewProps) {
    const { p } = props;
    const quantity = p.quantity || 0;
    const { price, totalPrice, availability, avColor } = formatValues(
        Number(p.price),
        p.availability,
        p.quantity || 0
    );

    let name = p.name;
    let mpn = p.mpn;

    if (props.highlightResults && p._highlightResult) {
        name = p._highlightResult.name.value;
        if (p._highlightResult.mpn.matchedWords.length > 0) {
            mpn = p._highlightResult.mpn.value;
        } else if (p._highlightResult.mpnSuff1.matchedWords.length > 0) {
            mpn = p.mpn[0] + p._highlightResult.mpnSuff1.value;
        } else if (p._highlightResult.mpnSuff2.matchedWords.length > 0) {
            mpn = p.mpn.slice(0, 2) + p._highlightResult.mpnSuff2.value;
        }
    }

    return (
        <Card>
            <Row>
                <Col xs="3" className="d-flex align-items-center pe-0">
                    <Card.Img src={p.imgUrl} />
                </Col>
                <Col xs="9" className="ps-0">
                    <Card.Body>
                        <Row className="gy-3">
                            <Col xs={(quantity && "10") || "12"}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: name }}
                                />
                            </Col>
                            {quantity > 0 && (
                                <Col
                                    xs="2"
                                    onClick={() => props.changeQty(-quantity)}
                                    role="button"
                                >
                                    <FontAwesomeIcon icon={faTrashCan} size="lg" />
                                </Col>
                            )}
                            <Col xs="12" style={{ fontSize: "14px" }}>
                                MPN:{" "}
                                <div
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{ __html: mpn }}
                                />
                            </Col>
                            <Col xs="6">
                                <Row xs="1">
                                    <Col
                                        className="pe-0"
                                        style={{
                                            fontSize: "12px"
                                        }}
                                    >
                                        Delivery Availability
                                    </Col>
                                    <Col className={avColor}>
                                        {availability.length > 0 ? (
                                            availability
                                        ) : (
                                            <Spinner size="sm" />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="6" className="text-end ps-0">
                                {(price.length > 0 && (
                                    <>
                                        <span className="fw-bold fs-5">
                                            {price}
                                        </span>
                                        <br />
                                        {quantity > 0 && (
                                            <span className="fs-6">
                                                Total: {totalPrice}
                                            </span>
                                        )}
                                    </>
                                )) || <Spinner />}
                            </Col>
                            <Col
                                xs="5"
                                className="pe-0"
                                style={{ fontSize: "14px" }}
                            >
                                <a
                                    href={p.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={props.handleClick}
                                >
                                    {p.merchant}
                                </a>
                            </Col>
                            <Col xs="7" className="text-end">
                                {(quantity && (
                                    <QuantitySetterView
                                        quantity={quantity}
                                        changeQty={props.changeQty}
                                    />
                                )) || (
                                    <Button
                                        className="shadow-sm"
                                        onClick={() => props.changeQty(1)}
                                    >
                                        Add to quote
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
}

function QuantitySetterView(props: {
    quantity: number;
    changeQty: (v: number) => void;
}) {
    const background = "#f3f3f3";
    const buttonStyle = {
        minHeight: "38px"
    };

    const MiniButton = (props: {
        v: string | number | React.ReactNode;
        onClick?: () => void;
        role?: string;
    }) => {
        return (
            <Col
                className={"d-flex align-items-center justify-content-center"}
                style={buttonStyle}
                onClick={props.onClick}
                role={props.role}
            >
                {props.v}
            </Col>
        );
    };

    return (
        <Row
            xs="3"
            className="g-0 rounded-2 ms-auto"
            style={{ background, maxHeight: "38px", maxWidth: "117.5px" }}
        >
            <MiniButton
                role="button"
                v={<FontAwesomeIcon icon={faMinus} />}
                onClick={() => props.changeQty(-1)}
            />
            <MiniButton v={props.quantity} />
            <MiniButton
                role="button"
                v={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => props.changeQty(1)}
            />
        </Row>
    );
}
