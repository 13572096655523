import { QueryDocumentSnapshot } from "firebase/firestore";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import {
    LoaderFunctionArgs,
    useLoaderData,
    useNavigate
} from "react-router-dom";
import { formatValues } from "./formatting";
import { CardProduct } from "./index.d";
import { useActiveQuote, useQuote, useQuoteDispatch } from "./model";
import QuoteSummaryView from "./QuoteSummaryView";

export default function Quote() {
    return <QuoteController view={QuoteView} />;
}

export async function loader(args: LoaderFunctionArgs) {
    let quoteId = args.params.quoteId ? args.params.quoteId : null;
    if (!quoteId) {
        throw new Error(`Quote doesn't exist`);
    }
    return quoteId;
}

interface QuoteViewProps {
    products: CardProduct[];
    total: number;
    date: Date | undefined;
    name: string;
    activeQuoteProds: QueryDocumentSnapshot<CardProduct>[];
    handleMoveToActive: () => Promise<void>;
}

function QuoteController(props: { view: FC<QuoteViewProps> }) {
    const quoteId = useLoaderData() as string;
    const activeQuoteProds = useActiveQuote();
    const { products, date, name } = useQuote(quoteId);
    const quoteDispatch = useQuoteDispatch();
    const navigate = useNavigate();

    const total = products.reduce(
        (acc, p) => acc + (p.price || 0) * p.quantity,
        0
    );

    const handleMoveToActive = async () => {
        await quoteDispatch({
            type: "to_active",
            value: { quoteId, date: new Date() }
        });
        navigate("/active-quote");
    };

    return props.view({
        products,
        total,
        date,
        name,
        activeQuoteProds,
        handleMoveToActive
    });
}

function QuoteView(props: QuoteViewProps) {
    const [showModal, setShowModal] = useState(false);

    const handleEditQuote = async () => {
        if (props.activeQuoteProds.length > 0) {
            setShowModal(true);
            return;
        }
        await props.handleMoveToActive();
    };

    return (
        <>
            <Helmet>
                <title>Your Saved Quote | Quote My Parts</title>
            </Helmet>
            <Row xs="1" className="g-3">
                {(props.products.length === 0 && (
                    <Col
                        className={
                            "d-flex justify-content-center align-items-center"
                        }
                    >
                        <span className="display-6 me-3">Loading Quote</span>
                        <Spinner />
                    </Col>
                )) || (
                    <>
                        <Col className="display-6 text-center">
                            Quote for {props.name}
                        </Col>
                        {props.date && (
                            <Col className="fs-6 text-center">
                                Date: {props.date.toLocaleDateString("en-GB")}
                            </Col>
                        )}
                        <QuoteSummaryView products={props.products} />
                        <Col className="text-end">
                            <Button
                                onClick={handleEditQuote}
                                className="button-qmp"
                            >
                                Edit Quote
                            </Button>
                        </Col>
                        <Col>
                            <ListGroup className="shadow-sm">
                                {props.products.map((p, i) => {
                                    const displayName = `${p.quantity} x ${p.name}`;
                                    const {
                                        price,
                                        availability,
                                        avColor,
                                        totalPrice
                                    } = formatValues(
                                        p.price,
                                        p.availability,
                                        p.quantity
                                    );
                                    return (
                                        <ListGroup.Item
                                            key={i}
                                            className="d-flex flex-column"
                                        >
                                            <Row xs="1">
                                                <Col className="text-start">
                                                    {displayName}
                                                </Col>
                                            </Row>
                                            <Row xs="2">
                                                <Col className="text-secondary">
                                                    <Row xs="1">
                                                        <Col>
                                                            {price} per unit
                                                        </Col>
                                                        <Col>
                                                            <a
                                                                href={p.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {p.merchant}
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row
                                                        xs="1"
                                                        className="text-end"
                                                    >
                                                        <Col className="fs-6 fw-bold">
                                                            {totalPrice}
                                                        </Col>
                                                        <Col
                                                            className={avColor}
                                                        >
                                                            {availability}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </Col>
                        <Modal
                            onHide={() => setShowModal(false)}
                            show={showModal}
                            centered={true}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Heads-Up</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Bringing this quote into edit mode will discard
                                the items you've added to your current draft so
                                far. Proceed?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={props.handleMoveToActive}
                                    className="button-qmp"
                                >
                                    Proceed
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Row>
        </>
    );
}
