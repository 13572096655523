import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp } from "firebase/firestore";
import { FormEvent, ForwardedRef, forwardRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import PhoneInput, { Value } from "react-phone-number-input/mobile";
import { Checkout } from "./index.d";

interface CheckoutFormViewProps {
    onSubmit: (payload: Checkout) => Promise<void>;
    totalAmount: string;
}

const WrappedPhoneInput = forwardRef(
    (props: any, ref: ForwardedRef<HTMLInputElement>) => {
        return (
            <FloatingLabel label="Mobile Number" className="flex-grow-1">
                <Form.Control {...props} ref={ref} />
                <Form.Control.Feedback>Phone required</Form.Control.Feedback>
            </FloatingLabel>
        );
    }
);

export default function CheckoutFormView(props: CheckoutFormViewProps) {
    const [formValidated, setFormValidated] = useState(false);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState<Value | undefined>(undefined);
    const [companyName, setCompanyName] = useState("");
    const [companyAddrLine1, setCompanyAddrLine1] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyPostalCode, setCompanyPostalCode] = useState("");
    const [deliveryAddrLine1, setDeliveryAddrLine1] = useState("");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [deliveryTime, setDeliveryTime] = useState("");

    const [cardNumber, setCardNumber] = useState("");
    const [validUntil, setValidUntil] = useState("");
    const [cardCvc, setCardCvc] = useState("");
    const [cardFirstName, setCardFirstName] = useState("");
    const [cardLastName, setCardLastName] = useState("");

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            setFormValidated(true);
            return;
        }
        await props.onSubmit({
            name,
            phone: phone as string,
            companyName,
            companyAddrLine1,
            companyCity,
            companyPostalCode,
            deliveryAddrLine1,
            deliveryCity,
            deliveryPostalCode,
            deliverAt: Timestamp.fromDate(
                new Date(deliveryDate + "T" + deliveryTime)
            )
        });
    };

    return (
        <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
            <FormGroup as={Row} xs="1" className="gy-2 text-start">
                <Col>Your Details</Col>
                <Col>
                    <FloatingLabel label="Full Name">
                        <Form.Control
                            name="name"
                            placeholder="Full Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Name required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <PhoneInput
                        defaultCountry="GB"
                        inputComponent={WrappedPhoneInput}
                        value={phone}
                        onChange={(num: Value | undefined) => setPhone(num)}
                        placeholder="Mobile Number"
                        required
                    />
                </Col>
                <Col className="mt-4">Company Details</Col>
                <Col>
                    <FloatingLabel label="Company Name">
                        <Form.Control
                            placeholder="Company Name"
                            name="companyName"
                            value={companyName}
                            onChange={e => setCompanyName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Company name required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Address">
                        <Form.Control
                            placeholder="Address"
                            name="companyAddrLine1"
                            value={companyAddrLine1}
                            onChange={e => setCompanyAddrLine1(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Company address required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Postal Code">
                        <Form.Control
                            placeholder="Postal Code"
                            name="companyPostalCode"
                            value={companyPostalCode}
                            onChange={e => setCompanyPostalCode(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Company postal code required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="City">
                        <Form.Control
                            placeholder="City"
                            name="companyCity"
                            value={companyCity}
                            onChange={e => setCompanyCity(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Company city required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col className="mt-4">Delivery Details</Col>
                <Col>
                    <FloatingLabel label="Address">
                        <Form.Control
                            placeholder="Address"
                            name="deliveryAddrLine1"
                            value={deliveryAddrLine1}
                            onChange={e => setDeliveryAddrLine1(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Delivery address required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Postal Code">
                        <Form.Control
                            placeholder="Postal Code"
                            name="deliveryPostalCode"
                            value={deliveryPostalCode}
                            onChange={e =>
                                setDeliveryPostalCode(e.target.value)
                            }
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Delivery postal code required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="City">
                        <Form.Control
                            placeholder="City"
                            name="deliveryCity"
                            value={deliveryCity}
                            onChange={e => setDeliveryCity(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Delivery city required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col style={{ fontSize: "12px" }}>
                    We deliver workdays between 07:30-17:00. You can select your
                    preferred delivery date below and we'll be in touch shortly
                    to confirm this.
                </Col>
                <Col>
                    <Row xs="2">
                        <Col>
                            <FloatingLabel label="Date">
                                <Form.Control
                                    placeholder="Date"
                                    type="date"
                                    name="deliveryDate"
                                    value={deliveryDate}
                                    onChange={e =>
                                        setDeliveryDate(e.target.value)
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Delivery postal code required
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label="Time">
                                <Form.Control
                                    placeholder="Delivery Date"
                                    type="time"
                                    name="deliveryTime"
                                    value={deliveryTime}
                                    onChange={e =>
                                        setDeliveryTime(e.target.value)
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Delivery postal code required
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Col>
                <Col className="mt-4">Payment Details</Col>
                <Col>
                    <FloatingLabel label="Card Number">
                        <Form.Control
                            placeholder="Card Number"
                            name="cardNumber"
                            value={cardNumber}
                            onChange={e => setCardNumber(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Card number required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <Row>
                        <Col xs="8">
                            <FloatingLabel label="Valid Until">
                                <Form.Control
                                    placeholder="Valid Until"
                                    type="month"
                                    name="validUntil"
                                    value={validUntil}
                                    onChange={e =>
                                        setValidUntil(e.target.value)
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Expiry date required
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col xs="4">
                            <FloatingLabel label="CVC">
                                <Form.Control
                                    placeholder="CVC"
                                    type="password"
                                    name="cardCvc"
                                    value={cardCvc}
                                    onChange={e => setCardCvc(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Card security code required
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <FloatingLabel label="First Name">
                        <Form.Control
                            placeholder="First Name"
                            name="cardFirstName"
                            value={cardFirstName}
                            onChange={e => setCardFirstName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Card first name required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Last Name">
                        <Form.Control
                            placeholder="Last Name"
                            name="cardLastName"
                            value={cardLastName}
                            onChange={e => setCardLastName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Card last name required
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col className="mt-4">
                    <Button className="w-100 button-qmp" type="submit">
                        Pay {props.totalAmount}
                        <FontAwesomeIcon icon={faLock} className="ms-3" />
                    </Button>
                </Col>
            </FormGroup>
        </Form>
    );
}
