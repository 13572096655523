import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ProductCard from "./ProductCard";

import Button from "react-bootstrap/Button";
import {
    useActiveQuote,
    useCheckoutDispatch,
    useLogEvent,
    useQuoteDispatch
} from "./model";
import { CardProduct, Checkout } from "./index.d";
import Modal from "react-bootstrap/esm/Modal";
import WaContactFormView from "./WaContactFormView";
import { sendQuoteToCustomer } from "./active-quote-controller";
import { useDisplayError, useDisplaySuccess } from "./AlertsProvider";
import { useLocalStorage } from "./LocalStorageProvider";
import { analyticsFromProductSnaps } from "./controller";
import CheckoutFormView from "./CheckoutFormView";
import QuoteSummaryView from "./QuoteSummaryView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

export default function ActiveQuote() {
    return <ActiveQuoteController view={ActiveQuoteView} />;
}

function EmptyActiveQuoteView() {
    const background = "#f3f3f3";
    return (
        <Card
            className="border-0 text-center mx-auto mt-4"
            style={{ background, maxWidth: "400px" }}
        >
            <Card.Body>
                <p>Empty quote 🪣</p>
                <p>
                    Try adding some products from the <Link to="/">search</Link>
                </p>
            </Card.Body>
        </Card>
    );
}

interface ActiveQuoteViewProps {
    products: CardProduct[];
    onWaSubmit: (name: string, phone: string) => Promise<void>;
    onOpenWaModal: () => void;
    onOpenCheckoutModal: () => void;
    onCheckoutSubmit: (payload: Checkout) => Promise<void>;
    onCoNotSendToWa: () => void;
    onCoSendToWa: () => void;
}

/**
 * Shows the current quote products
 */
function ActiveQuoteController(props: { view: FC<ActiveQuoteViewProps> }) {
    const products = useActiveQuote();
    const quoteDispatch = useQuoteDispatch();
    const displaySuccess = useDisplaySuccess();
    const displayError = useDisplayError();
    const navigate = useNavigate();
    const logEvent = useLogEvent();
    const { localStorageObj } = useLocalStorage();
    const checkoutDispatch = useCheckoutDispatch();

    const handleWaSubmit = async (name: string, phone: string) => {
        try {
            logEvent("quote_saved", analyticsFromProductSnaps(products));
            const oldQuoteId = localStorageObj.activeQuoteId;
            await sendQuoteToCustomer(name, phone, oldQuoteId);
            await quoteDispatch({
                type: "to_final",
                value: { date: new Date(), name }
            });
            displaySuccess("Quote sent! Please check your Whatsapp.");
            navigate(`/quote/${oldQuoteId}`);
        } catch (e) {
            const err = e as Error;
            displayError(err);
        }
    };

    const handleOpenWaModal = () => {
        logEvent("begin_send_to_wa", analyticsFromProductSnaps(products));
    };

    const handleCheckoutSubmit = async (payload: Checkout) => {
        const coRef = await checkoutDispatch({ type: "add", value: payload });
        logEvent("purchase", {
            ...analyticsFromProductSnaps(products),
            transaction_id: coRef.id
        });
    };

    const handleOpenCheckoutModal = () => {
        logEvent("begin_checkout", analyticsFromProductSnaps(products));
    };

    const handleCoNotSendToWa = () => {
        logEvent(
            "checkout_not_send_to_wa",
            analyticsFromProductSnaps(products)
        );
    };

    const handleCoSendToWa = () => {
        logEvent("checkout_send_to_wa", analyticsFromProductSnaps(products));
    };

    return props.view({
        products: products.map(p => p.data()),
        onWaSubmit: handleWaSubmit,
        onOpenWaModal: handleOpenWaModal,
        onOpenCheckoutModal: handleOpenCheckoutModal,
        onCheckoutSubmit: handleCheckoutSubmit,
        onCoSendToWa: handleCoSendToWa,
        onCoNotSendToWa: handleCoNotSendToWa
    });
}

function ActiveQuoteView(props: ActiveQuoteViewProps) {
    const [showWaModal, setShowWaModal] = useState(false);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [showPostCheckoutModal, setShowPostCheckoutModal] = useState(false);

    const totalAmountFormatted = (
        1.2 *
        props.products.reduce((acc, p) => acc + (p.price || 0) * p.quantity, 0)
    ).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
    });

    useEffect(() => window.scrollTo(0, 0), []);

    const handleWaSubmit = async (name: string, phone: string) => {
        await props.onWaSubmit(name, phone);
        setShowWaModal(false);
    };

    const handleOpenWaModal = () => {
        setShowWaModal(true);
        props.onOpenWaModal();
    };

    const handleCheckoutSubmit = async (payload: Checkout) => {
        await props.onCheckoutSubmit(payload);
        setShowCheckoutModal(false);
        setShowPostCheckoutModal(true);
    };

    const handleOpenCheckoutModal = () => {
        setShowCheckoutModal(true);
        props.onOpenCheckoutModal();
    };

    const handleNotSendToWa = () => {
        props.onCoNotSendToWa();
        setShowPostCheckoutModal(false);
    };

    const handleSendToWa = () => {
        props.onCoSendToWa();
        setShowPostCheckoutModal(false);
        setShowWaModal(true);
    };

    return (
        <>
            <Helmet>
                <title>Current Quote | Quote My Parts</title>
            </Helmet>
            <Row xs="1" className="gy-3">
                <Col className="text-center">
                    <p className="display-6">Your Quote</p>
                </Col>
                {(props.products.length === 0 && (
                    <Col>
                        <EmptyActiveQuoteView />
                    </Col>
                )) || (
                    <>
                        <QuoteSummaryView products={props.products} />
                        <Col>
                            <Row className="gx-3">
                                <Col xs="7">
                                    <Button
                                        variant="secondary"
                                        className="shadow-sm w-100 border-0"
                                        onClick={handleOpenWaModal}
                                    >
                                        Save to WhatsApp
                                    </Button>
                                </Col>
                                <Col xs="5">
                                    <Button
                                        className="button-qmp w-100"
                                        onClick={handleOpenCheckoutModal}
                                    >
                                        Checkout
                                        <FontAwesomeIcon
                                            icon={faCartShopping}
                                            className="ms-2"
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row xs="1" md="2" xl="3" className="gy-2">
                                {props.products.map((p, i) => (
                                    <Col key={i}>
                                        <ProductCard product={p} index={i} />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </>
                )}
            </Row>
            <Modal
                show={showWaModal}
                onHide={() => setShowWaModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Link to Your Whatsapp</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Come back to your quote anytime:</p>
                    <ul>
                        <li>Access it from the WhatsApp link</li>
                        <li>Forward it to your merchant as a request</li>
                    </ul>
                    <WaContactFormView handleSubmit={handleWaSubmit} />
                </Modal.Body>
            </Modal>
            <Modal
                show={showCheckoutModal}
                onHide={() => setShowCheckoutModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Checkout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CheckoutFormView
                        onSubmit={handleCheckoutSubmit}
                        totalAmount={totalAmountFormatted}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={showPostCheckoutModal}
                onHide={() => setShowPostCheckoutModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delivery At Full Capacity!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        We've reached full delivery capacity and are unable to
                        serve this delivery.
                    </p>
                    <p>
                        The service is still in trial stages. We didn't charge
                        your credit card.
                    </p>
                    <p>We apologise!</p>
                    <p>
                        Would you like to save this quote to WhatsApp instead?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNotSendToWa}>
                        No
                    </Button>
                    <Button className="button-qmp" onClick={handleSendToWa}>
                        Save to WhatsApp
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
