import { FC } from "react";

import { AlertsProvider } from "./AlertsProvider";
import Header from "./Header";
import Container from "react-bootstrap/esm/Container";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Spinner } from "react-bootstrap";
import { useLocalStorage } from "./LocalStorageProvider";
import { Helmet } from "react-helmet";
import Image from "react-bootstrap/Image";
import { useLogEvent } from "./model";

export default function App() {
    return <AppController view={AppView} />;
}

interface AppViewProps {
    loading: boolean;
    handleClick: () => void;
}

/**
 * The root component wraps the Outlet with the alerts provider to be able to
 * show alerts and modify the state from any part of the code.
 */
function AppController(props: { view: FC<AppViewProps> }) {
    const { localStorageObj } = useLocalStorage();
    const logEvent = useLogEvent();

    const handleClick = () => {
        logEvent("contact_whatsapp", {});
    }

    return props.view({ loading: localStorageObj.activeQuoteId.length === 0, handleClick });
}

function AppView(props: AppViewProps) {
    if (props.loading) {
        return (
            <div
                className={
                    "d-flex justify-content-center align-items-center vh-100"
                }
            >
                <Spinner />
            </div>
        );
    }
    return (
        <AlertsProvider>
            <>
                <Helmet>
                    <title>
                        Quote My Parts | Price Comparison for Plumbing Supplies
                    </title>
                </Helmet>
                <Header />
                <article style={{ paddingTop: "80px", minHeight: "93.8vh" }}>
                    <Container fluid="lg" className="mt-4">
                        <Outlet />
                    </Container>
                </article>
                <Footer />
                <a href="https://wa.me/447436905262" onClick={props.handleClick}>
                    <Image
                        src="/assets/WhatsAppButtonGreenMedium.svg"
                        className="position-fixed bottom-0 end-0 me-2 mb-4 shadow"
                    />
                </a>
            </>
        </AlertsProvider>
    );
}
