import AppConfig from "./AppConfig";

export async function sendQuoteToCustomer(
    name: string,
    phone: string,
    quoteId: string
) {
    const res = await fetch(`${AppConfig.BACKEND_URL}/save-quote`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        body: JSON.stringify({
            name,
            phone,
            quoteId
        })
    });
    if (!res.ok) {
        throw new Error(`Couldn't send quote to WhatsApp, status ${res.status}`);
    }
}
