export function formatValues(
    p: number | undefined,
    a: string | undefined,
    q: number
) {
    let price = "";
    let totalPrice = "";
    if (p) {
        if (p > 0) {
            price = p.toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP"
            });
            totalPrice = (p * q).toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP"
            });
        } else {
            price = "Unknown";
            totalPrice = "Unknown";
        }
    }
    let avColor = "";
    let availability = "";
    if (a) {
        availability = a === "InStock" ? "In Stock" : "Out Of Stock";
        avColor = a === "InStock" ? "text-success" : "text-danger";
    }
    return { price, availability, avColor, totalPrice };
}


