import { FC, MouseEvent, MouseEventHandler, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { CardProduct } from "./index.d";
import { analyticsFromProductSnaps } from "./controller";
import { useActiveQuote, useLogEvent } from "./model";
import QuoteSidebarView from "./QuoteSidebarView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";

export default function Header() {
    return <HeaderController view={HeaderView} />;
}

interface HeaderViewProps {
    products: CardProduct[];
    handleQuoteOpen: MouseEventHandler<HTMLElement>; // TODO Make this a mouse event handler
    navigateToQuote: () => void;
}

function HeaderController(props: { view: FC<HeaderViewProps> }) {
    const productSnaps = useActiveQuote();
    const logEvent = useLogEvent();
    const navigate = useNavigate();

    const handleQuoteOpen = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        logEvent("peek_cart", analyticsFromProductSnaps(productSnaps));
    };

    const navigateToQuote = () => {
        logEvent("view_cart", analyticsFromProductSnaps(productSnaps));
        navigate("/active-quote");
    };

    return props.view({
        products: productSnaps.map(ps => ps.data()),
        handleQuoteOpen,
        navigateToQuote
    });
}

function HeaderView(props: HeaderViewProps) {
    const numItems = props.products.length;
    const [showSidebar, setShowSidebar] = useState(false);

    const handleQuoteOpen = (e: MouseEvent<HTMLElement>) => {
        setShowSidebar(true);
        props.handleQuoteOpen(e);
    }

    const navigateToQuote = () => {
        setShowSidebar(false);
        props.navigateToQuote();
    }

    return (
        <header>
            <Navbar
                bg="light"
                fixed="top"
                className="justify-content-between shadow-sm"
            >
                <Container fluid="lg">
                    <Navbar.Brand>
                        <Image
                            src="/assets/full-logo.svg"
                            style={{ maxHeight: "35px" }}
                            className="img-fluid"
                        />
                    </Navbar.Brand>
                    <Nav>
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={"/"}
                                className="text-center fs-6"
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                <br />
                                Search
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={"/active-quote"}
                                className="text-center position-relative"
                                onClick={handleQuoteOpen}
                            >
                                <FontAwesomeIcon icon={faFileLines} />
                                <br />
                                Your Quote
                                {numItems > 0 && (
                                    <Badge
                                        pill
                                        bg="danger"
                                        className="position-absolute top-0 start-50"
                                    >
                                        {numItems}
                                    </Badge>
                                )}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </Navbar>
            <QuoteSidebarView
                products={props.products}
                show={showSidebar}
                onClose={() => setShowSidebar(false)}
                onViewQuote={navigateToQuote}
            />
        </header>
    );
}
