import { FC, MouseEvent, ReactNode, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionButton, {
    useAccordionButton
} from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "react-phone-number-input/style.css";
import { useAddSubscription, useLogEvent } from "./model";
import WaContactFormView from "./WaContactFormView";

export default function NoSearchHits(props: { query: string }) {
    return (
        <NoSearchHitsController view={NoSearchHitsView} query={props.query} />
    );
}

function CustomAccordionButtonViewController(props: {
    children: ReactNode;
    eventKey: string;
    handleExpandAccordion: () => void;
}) {
    const background = "#f3f3f3";
    const decoratedOnClick = useAccordionButton(props.eventKey);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        decoratedOnClick(e);
        props.handleExpandAccordion();
    };

    return (
        <AccordionButton
            style={{ backgroundColor: background }}
            onClick={handleClick}
        >
            {props.children}
        </AccordionButton>
    );
}

interface NoSearchHitsViewProps {
    handleSubmit: (name: string, phone: string) => Promise<void>;
    thankYouMsg: string;
    handleExpandAccordion: () => void;
    query: string;
}

function NoSearchHitsController(props: {
    view: FC<NoSearchHitsViewProps>;
    query: string;
}) {
    const [thankYouMsg, setThankYouMsg] = useState("");
    const logEvent = useLogEvent();
    const addSubscription = useAddSubscription();

    const handleSubmit = async (name: string, phone: string) => {
        await addSubscription({
            date: new Date(),
            name,
            phone,
            reason: "missing_product",
            additionalInfo: props.query
        });
        logEvent("missing_prod_subscription", {
            search_term: props.query
        });
        setThankYouMsg(
            `Thank you for your patience, ${name}, we'll be in touch soon!`
        );
    };

    const handleExpandAccordion = () => {
        logEvent("expand_no_hits", { search_term: props.query });
    };

    return props.view({
        handleSubmit,
        thankYouMsg,
        handleExpandAccordion,
        query: props.query
    });
}

function NoSearchHitsView(props: NoSearchHitsViewProps) {
    const background = "#f3f3f3";

    return (
        <Row className="text-center mt-4">
            <Col>
                <Card
                    className="border-0 mx-auto"
                    style={{ background, maxWidth: "400px" }}
                >
                    <Card.Body>
                        <p>😕</p>
                        <p>
                            We're sorry, but <b>{props.query}</b> is not in our
                            database. We're working hard on adding it!
                        </p>
                    </Card.Body>
                    <Card.Footer
                        className="p-1 border-0"
                        style={{ background }}
                    >
                        {(props.thankYouMsg.length > 0 && (
                            <Row
                                className="align-items-center"
                                style={{ minHeight: "235px" }}
                            >
                                <p>{props.thankYouMsg} ✅</p>
                            </Row>
                        )) || (
                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <CustomAccordionButtonViewController
                                        eventKey="0"
                                        handleExpandAccordion={
                                            props.handleExpandAccordion
                                        }
                                    >
                                        Get notified when it's available
                                    </CustomAccordionButtonViewController>
                                    <Accordion.Body style={{ background }}>
                                        <WaContactFormView
                                            handleSubmit={props.handleSubmit}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )}
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
}
