import algoliasearch from "algoliasearch/lite";
import { CardProduct, Product } from "./index.d";
import { useLogEvent, usePricePercentage, useRecordQuery } from "./model";
import Config from "./AppConfig";
import { QueryDocumentSnapshot } from "firebase/firestore";

const algolia = algoliasearch(Config.ALGOLIA_APP_ID, Config.ALGOLIA_SEARCH_KEY);

export async function fetchLiveData(products: Product[]) {
    const prodRes = await fetch(`${Config.BACKEND_URL}/prod-info`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        body: JSON.stringify(
            products.map(p => ({
                objectID: p.objectID,
                merchant: p.merchant,
                url: p.url
            }))
        )
    });
    if (!prodRes.ok) {
        throw new Error(
            `Backend responded with ${prodRes.status}: ` + prodRes.statusText
        );
    }
    return (await prodRes.json()) as Product[];
}

export function updateWithLiveData(
    products: CardProduct[],
    liveData: Product[]
) {
    return products.map(p => {
        // In case of performance issues use a map to store the products
        const liveProd = liveData.find(live => live.objectID === p.objectID);
        if (!liveProd) {
            return p;
        }
        // p.price = liveProd.price;
        p.availability = liveProd.availability;
        return p;
    });
}

export function useRunQuery() {
    const logEvent = useLogEvent();
    const recordQuery = useRecordQuery();
    const pricePercentage = usePricePercentage();

    return async (query: string) => {
        await recordQuery(query);
        const queryRes = await algolia.search<Product>([
            { indexName: Config.ALGOLIA_INDEX, query }
        ]);
        const queryProds = queryRes.results[0].hits;
        if (queryProds.length === 0) {
            logEvent("no_results", { search_term: query });
        }
        return queryProds.map(qp => ({
            ...qp,
            price: (qp.price || 0) * pricePercentage
        }));
    };
}

export function assignQuantity(
    products: Product[],
    quoteProds: QueryDocumentSnapshot<Product>[]
) {
    return products.map(p => {
        p.quantity = 0;
        const quoteProduct = quoteProds.find(
            qp => qp.get("objectID") === p.objectID
        );
        if (quoteProduct) {
            p.quantity = quoteProduct.get("quantity");
        }
        return p as CardProduct;
    });
}
