import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export default function Footer() {
    return (
        <footer className="fs-6 mt-3 bg-light">
            <Container fluid="lg">
                <Row>
                    <Col>
                        <p className="my-2">
                            © 2023 by{" "}
                            <a
                                href="https://tradespeopletech.co.uk"
                                target="_blank"
                                rel="noreferrer"
                                className="link-dark"
                            >
                                Tradespeople Tech
                            </a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
